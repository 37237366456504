<template>
  <i18n-t
    keypath="notifications.body.betUnacceptedOrdinary"
    tag="p"
    class="text"
  >
    <template #eventName>
      <span>{{ eventName }}</span>
    </template>
    <template #amount>
      <b>{{ notificationAmount }}</b>
    </template>
  </i18n-t>
</template>

<script setup lang="ts">
import { parseLanguageMap } from '@st/utils'
import type { BetOrdinaryNotification } from '../../../../types'

const props = defineProps<{
  notification: BetOrdinaryNotification
}>()
const { notification } = toRefs(props)
const { notificationAmount } = useCurrencyNotification(notification)

const i18n = useI18n()
const eventName = computed(() =>
  parseLanguageMap(notification.value.data.eventName ?? '', i18n.locale.value),
)
</script>

<style scoped>
.text {
  margin: 0;
  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);

  b {
    font: var(--desktop-text-xs-semibold);
    color: var(--text-primary);
  }
}
</style>
